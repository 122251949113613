<template>
  <div>
    <Row>
      <i-col span="24">
        <Select v-model="chooseCompanyId">
            <Option v-for="item in childCompanyList" :value="item.id" :key="item.id">{{ item.name }}</Option>
        </Select>
      </i-col>
    </Row>
    <Row class="p-t-5 p-b-10 text-center">
      <i-col span="24">
        <RadioGroup v-model="chooseGbType" type="button" button-style="solid">
            <Radio label="1" style="width:162px">行业组</Radio>
            <Radio label="2" style="width:162px">销售</Radio>
        </RadioGroup>
      </i-col>
    </Row>

    <template v-if="chooseGbType==='1'">
      <div style="width:290px;height:180px;" id="kpiIndustryContractReport"></div>
      <Table class="kpiTable m-t-10" size="small" :columns="industryContractColumn" :data="industryContractData" :row-class-name="rowClassName"
        @on-row-click="handleClickRow"></Table>

      <h2 class="text-center p-t-20 p-b-10">任务完成率</h2>
      <Row class="text-center">
        <i-col span="8">
          <p class="remark p-b-5">签约目标额</p>
          <b class="text-16">{{formatMoney(countTargetContractAmountValue)}}</b>
        </i-col>
        <i-col span="10">
          <p class="remark p-b-5">签约额</p>
          <b class="text-16">{{formatMoney(countKpiContractAmountValue)}}</b>
        </i-col>
        <i-col span="6">
          <p class="remark p-b-5">贡献率</p>
          <b class="text-16">{{countTargetContractFinishRateValue}}%</b>
        </i-col>
      </Row>

      <h2 class="text-center p-t-20 p-b-10">销售贡献</h2>
      <Row  class="table-title" :gutter="8">
        <i-col span="7">销售</i-col>
        <i-col span="10" class="text-right">签约额(元)</i-col>
        <i-col span="7" class="text-right">贡献率</i-col>
      </Row>
      <Row v-for="(item,index) in listContractAmountGroupByUserData" :key="index" :gutter="8" class="p-t-10">
        <i-col span="7">{{item.name}}</i-col>
        <i-col span="10" class="text-right">{{formatMoney(item.value)}}</i-col>
        <i-col span="7" class="text-right">{{item.rate}} %</i-col>
      </Row>
      <div v-show="showArray.length>currentPageSize" class="paging_style">
        <Page size="small" :total="showArray.length" :page-size="currentPageSize" :current="currentPageNumber" @on-change="handlePageChange"></Page>
      </div>
      <p v-if="listContractAmountGroupByUserData.length===0" class="remark p-t-20 text-center">暂无数据</p>
    </template>
    <template v-else>
      <Table class="kpiTable" size="small" :columns="abilityRankingColumn" :data="abilityRankingData" :row-class-name="rowClassName"
        @on-row-click="handleClickRow"></Table>

      <template v-if="chooseTableId">
        <h2 class="text-center p-t-20 p-b-10">销售合同</h2>
        <Row  class="table-title" :gutter="8">
          <i-col span="12">客户名称</i-col>
          <i-col span="6">最后签约日期</i-col>
          <i-col span="6" class="text-right">签约总额</i-col>
        </Row>
        <Row v-for="(item,index) in userSignAdvertiserData" :key="index" :gutter="8" class="p-t-10">
          <i-col span="12">
            <Tooltip v-if="item.advertiserName.length>12" placement="bottom-start" :content="item.advertiserName">
              {{item.advertiserName.substr(0,12)}}...
            </Tooltip>
            <span v-else>{{item.advertiserName}}</span>
          </i-col>
          <i-col span="6">{{item.approvedTime}}</i-col>
          <i-col span="6" class="text-right">{{formatMoney(item.amount)}}</i-col>
        </Row>
        <p v-if="userSignAdvertiserData.length===0" class="remark p-t-20 text-center">暂无数据</p>
      </template>
    </template>
  </div>
</template>

<script>
import * as echarts from 'echarts'

import { toMoney } from '@/utils/wnumb_own'
import { getCompany } from '@/api/os/company'
import { listChildrenCompany } from '@/api/dw/datav'
import {
  listContractGroupByIndustryGroup, countTargetContractAmount, countKpiContractAmount,
  countTargetContractFinishRate, listContractAmountGroupByUser, countAbilityRankingGbUser,
  listUserSignAdvertiser
} from '@/api/dw/kpi'

export default {
  data () {
    return {
      loginCompanyId: this.$store.getters.token.userInfo.companyId,
      publisherId: this.$store.getters.token.userInfo.publisherId,
      dataSourcePie: null,

      chooseCompanyId: '0',
      chooseGbType: '1', // 查询类型
      chooseTableId: null,

      childCompanyList: [],
      industryContractColumn: [
        { title: '行业组', key: 'name' },
        {
          title: '签约额(元)',
          align: 'right',
          width: 120,
          render: (h, params) => {
            return h('span', toMoney(params.row.value))
          }
        },
        {
          title: '贡献率',
          align: 'right',
          render: (h, params) => {
            return h('span', params.row.rate + ' %')
          }
        }
      ],
      industryContractData: [],

      countTargetContractAmountValue: null,
      countKpiContractAmountValue: null,
      countTargetContractFinishRateValue: null,
      listContractAmountGroupByUserData: [],

      abilityRankingColumn: [
        { title: '姓名', key: 'name' },
        { title: '排名平均分', align: 'center', key: 'value' },
        { title: '综合排名', align: 'center', key: 'number' }
      ],
      abilityRankingData: [], // 销售能力排行数据
      userSignAdvertiserData: [],

      showArray: [],
      currentPageSize: 10,
      currentPageNumber: 1
    }
  },
  mounted () {
    this.chooseCompanyId = this.companyId
    this.loadCompanyList()
    this.initPageData()
  },
  methods: {
    loadCompanyList () {
      if (this.companyId !== '0') { // 表示代理商
        getCompany({ companyId: this.companyId }).then(res => {
          if (res && !res.errcode) {
            this.childCompanyList = [
              { id: this.companyId, name: res.name }
            ]
          }
        })
      } else {
        const postData1 = {
          endDate: this.searchSchedule.endDate,
          loginCompanyId: this.loginCompanyId,
          publisherId: this.publisherId,
          startDate: this.searchSchedule.startDate,
          type: 2 // 2代理商;3运维公司
        }
        listChildrenCompany(postData1).then(res => {
          this.childCompanyList = res
        })
      }
    },
    initPageData () {
      if (this.chooseGbType === '1') {
        this.loadIndustryData()
        this.loadTaskCompleteData()
      } else {
        this.loadAbilityRankingGbUser()
      }
    },
    loadIndustryData () {
      const queryModel = {
        endDate: this.searchSchedule.endDate,
        companyId: this.chooseCompanyId,
        publisherId: this.publisherId,
        startDate: this.searchSchedule.startDate
      }

      listContractGroupByIndustryGroup(queryModel).then(res => {
        this.industryContractData = res
        const reportData = res.map(element => {
          return {
            value: element.rate,
            name: element.name
          }
        })
        this.initChart(reportData)
      })
    },
    initChart (reportData) {
      if (this.dataSourcePie != null && this.dataSourcePie !== '' && this.dataSourcePie !== undefined) {
        this.dataSourcePie.dispose()
      }

      this.$nextTick(() => {
        // 防止页面被切换，元素被移除
        if (document.getElementById('kpiIndustryContractReport')) {
          this.dataSourcePie = echarts.init(document.getElementById('kpiIndustryContractReport'))
          const option = {
            tooltip: {
              trigger: 'item'
            },
            series: [
              {
                name: '行业组贡献率分布',
                type: 'pie',
                radius: ['70%', '90%'],
                avoidLabelOverlap: false,
                label: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: '15',
                    formatter: '{d}%\n{b}',
                    color: '#fff'
                  }
                },
                labelLine: {
                  show: false
                },
                data: reportData
              }
            ]
          }

          this.dataSourcePie.setOption(option, true)

          // 默认选中第一项
          let index = 0
          const _self = this
          _self.dataSourcePie.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: 0
          }) // 设置默认选中高亮部分
          _self.dataSourcePie.on('mouseover', function (e) {
            if (e.dataIndex !== index) {
              _self.dataSourcePie.dispatchAction({
                type: 'downplay',
                seriesIndex: 0,
                dataIndex: index
              })
            }
          })
          _self.dataSourcePie.on('mouseout', function (e) {
            index = e.dataIndex
            _self.dataSourcePie.dispatchAction({
              type: 'highlight',
              seriesIndex: 0,
              dataIndex: e.dataIndex
            })
          })
        }
      })
    },
    rowClassName (row, index) {
      return row.id === this.chooseTableId ? 'situation-table-choose-row' : 'situation-table-row'
    },
    handleClickRow (params, index) {
      this.chooseTableId = (this.chooseTableId === params.id) ? null : params.id
      this.$store.commit('set_situation_gbId', this.chooseTableId)

      if (this.chooseGbType === '1') {
        this.loadTaskCompleteData()
      } else { // 开始载入销售合同
        this.loadUserSignAdvertiser()
      }
    },
    loadTaskCompleteData () { // 任务完成率
      const queryModel = {
        endDate: this.searchSchedule.endDate,
        companyId: this.chooseCompanyId,
        publisherId: this.publisherId,
        startDate: this.searchSchedule.startDate,
        industryGroupId: this.chooseTableId
      }

      countTargetContractAmount(queryModel).then(res => {
        this.countTargetContractAmountValue = res[0].value
      })
      countKpiContractAmount(queryModel).then(res => {
        this.countKpiContractAmountValue = res[0].value
      })
      countTargetContractFinishRate(queryModel).then(res => {
        this.countTargetContractFinishRateValue = res[0].value
      })
      listContractAmountGroupByUser(queryModel).then(res => {
        this.showArray = res
        this.handlePageChange(1)
      })
    },
    loadAbilityRankingGbUser () {
      const queryModel = {
        endDate: this.searchSchedule.endDate,
        companyId: this.chooseCompanyId,
        publisherId: this.publisherId,
        startDate: this.searchSchedule.startDate
      }

      countAbilityRankingGbUser(queryModel).then(res => {
        this.abilityRankingData = res
      })
    },
    loadUserSignAdvertiser () {
      const queryModel = {
        endDate: this.searchSchedule.endDate,
        companyId: this.chooseCompanyId,
        publisherId: this.publisherId,
        startDate: this.searchSchedule.startDate,
        rankUserId: this.chooseTableId
      }

      listUserSignAdvertiser(queryModel).then(res => {
        this.userSignAdvertiserData = res
      })
    },
    formatMoney (money) {
      return toMoney(money)
    },
    handlePageChange (page) {
      this.currentPageNumber = page
      const newArray = []
      for (let index = this.currentPageSize * (page - 1); index < this.currentPageSize * page; index++) {
        if (index < this.showArray.length) {
          newArray.push(this.showArray[index])
        } else {
          break
        }
      }

      this.listContractAmountGroupByUserData = newArray
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    },
    companyId () {
      return this.$store.state.situationWeb.companyId
    }
  },
  watch: {
    searchSchedule: {
      deep: true,
      handler (newValue) {
        this.initPageData()
      }
    },
    chooseCompanyId (val) {
      this.$store.commit('set_situation_companyId', val)
      this.initPageData()
    },
    chooseGbType (val) {
      this.chooseTableId = null
      this.$store.commit('set_situation_gbId', null)
      this.$store.commit('set_situation_gbType', val)

      this.initPageData()
    }
  }
}
</script>

<style>
.kpiTable .ivu-table-cell{
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  box-sizing: border-box;
}
</style>
